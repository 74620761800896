import { svgs } from "#/ui/svgs";

type SVGIconProps = {
  className: string;
  onClick?: () => void;
  svg:
    | "largeArrow"
    | "add-person"
    | "percentage"
    | "magic"
    | "dollar"
    | "chevronDouble"
    | "type"
    | "menu"
    | "chevronWide"
    | "note"
    | "circle"
    | "arrowControls"
    | "list"
    | "mm-connect"
    | "grabber"
    | "checkList"
    | "chevronThickDown"
    | "grid"
    | "doubleCheck"
    | "success"
    | "error"
    | "staggered"
    | "categories"
    | "receipt"
    | "link"
    | "location"
    | "id"
    | "kanban";
};

export const SVGIcon = ({ className, svg, onClick }: SVGIconProps) => {
  return (
    <div className={className} onClick={onClick}>
      {svgs[svg]}
    </div>
  );
};
